import { mergeDeepLeft } from 'ramda';

import {
  FontSize as FontSizes, FontFamilies,
} from '@powdr/constants';

/** ***************************************** */
/* Default Font Overrides */
/** ***************************************** */
const FontSizeOverrides = {};

const FontSize = mergeDeepLeft(FontSizeOverrides, FontSizes);

const ComponentFontAdjustments = {};

const fonts = {
  [FontFamilies.PRI_HDR_BLK]: 'font-family: "PaysonWF Bold Italic"',
  [FontFamilies.SEC_HDR_BLK]: 'font-family: "PaysonWF Italic"',
  [FontFamilies.PRI_BDY_HVY]: 'font-family: "PaysonWF Bold Italic"',
  [FontFamilies.PRI_BDY_MED]: 'font-family: "PaysonWF Italic"',
};

export const fontTheme = {
  fonts,
  FontSize,
  ComponentFontAdjustments,
};
